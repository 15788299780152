<template>
    <div class="match_container">
        <div class="matching column_center" v-if="order_status === 0">
            <div class="set_time">{{pass_time | timeToClock}}</div>
            <div class="matching_status">待接单</div>
            <div class="matching_status_text"><span style="color: #de1f37;">{{orderData && orderData.order_info.back_car==0?'专车':'顺风车'}}</span>匹配中，请耐心等待</div>
            <div class="matching_tip column_center_start">
                <span>10分钟未匹配车辆，平台客服即会介入开启人工匹配</span>
                <span>请保持手机通畅</span>
            </div>
            <el-button v-if="orderData" @click.stop="cannelOrder">申请取消</el-button>
        </div>
        <div class="match_success column_center" v-if="order_status === 1">
            <img :src="orderData && orderData.motorman_info.avatar?orderData.motorman_info.avatar:'@assets/imgs/156.jpg'" class="order_avatar" alt="">
            <span class="order_user_name">{{orderData && orderData.motorman_info.real_name}}</span>
            <el-rate v-model="rate_val" disabled show-score text-color="#f54123" allow-half :colors="colors" score-template="{value}"></el-rate>
            <div class="car_number">
                {{orderData && orderData.motorman_info.car_number}}（{{orderData && orderData.motorman_info.car_type_name}}）
                <span v-if="orderData.order_info.order_status==20">已接单</span>
                <span v-else-if="orderData.order_info.order_status==30">运输中</span>
                <span v-else-if="orderData.order_info.order_status==31">待签收</span>
                <span v-else-if="orderData.order_info.order_status==40">已完成</span>
                <span v-else-if="orderData.order_info.order_status==51">已取消</span>
            </div>
            <div class="success_info">
                <div class="success_title flex_between_center">
                    <div class="suc_lf flex_start_center">
                        {{orderData && orderData.order_info.trip_type==1?'实时':'预约'}} {{orderData && orderData.order_info.receiving_time | timestampToTime}}
                        <el-tag v-if="orderData.order_info.order_status==20" effect="dark" size="mini">已接单</el-tag>
                        <el-tag v-else-if="orderData.order_info.order_status==30" effect="dark" size="mini">运输中</el-tag>
                        <el-tag v-else-if="orderData.order_info.order_status==31" effect="dark" size="mini">待签收</el-tag>
                        <el-tag v-else-if="orderData.order_info.order_status==40" effect="dark" size="mini">已完成</el-tag>
                        <el-tag v-else-if="orderData.order_info.order_status==51" effect="dark" size="mini">已取消</el-tag>
                    </div>
                    <div class="suc_rg">费用：<span style="color:#f54123;font-size:22px;">￥{{orderData && orderData.estimate_amount}}</span></div>
                </div>
                <div class="success_content flex_start_center">
                    <div class="order_mileage">
                        <div class="mileage_lf flex_start">
                            <div class="start_mileage flex_start">
                                <div class="start_icon">装</div>
                                <div class="column_start" style="flex: 1;">
                                    <span>天河软件园华景园区</span>
                                    <span>胡先生（18520987432）</span>
                                </div>
                            </div>
                            <img src="@assets/imgs/u912.png" class="right_icon" alt="">
                            <div class="end_mileage flex_start">
                                <div class="end_icon">卸</div>
                                <div class="column_start" style="flex: 1;">
                                    <span>广州市南沙港港口码头</span>
                                    <span>王先生（18348937024）</span>
                                </div>
                            </div>
                        </div>
                        <div class="mileage_num">小货车 运输里程：56.65公里</div>
                    </div>
                    <el-button @click="toOrderList">查看订单</el-button>
                </div>
            </div>
        </div>
        <div class="match_info column_center" v-if="order_status === 2">
            <img src="@assets/imgs/u465.png" alt="">
            <span>当前无订单正在匹配，请前往下单</span>
            <el-button class="order_btn" type="primary" @click.stop="$router.push('/index/placeOrder')">前往下单</el-button>
        </div>
        <cancel-tips @select="selectTips" @cancel="showCancelTips = false" @confirm="confirmCancel" :show="showCancelTips"></cancel-tips>
    </div>
</template>

<script>
var qqmapsdk
var timer
var isShowTips = false
var canPost = true
import {handleGetOrder,handleUpgradeCar,handleGetCannelTips,handleCannelOrder} from '@api/matchOrder'
import cancelTips from "@/components/cancelTips"
export default {
    components:{
        cancelTips
    },
    data() {
        return {
            msg_id:'',
            showCancelTips:false,//展示取消订单的弹窗
            canUpgrade: false,
            latitude:'',
            longitude:'',
            polyline: [],
            markers: [],
            wayPoint: [],
            hidden: true,
            payment_id: null,
            load_num:0,
            unload_num:0,
            pass_time:0,
            orderData:null,
            order_status: '',
            order_sn:'',
            // 0
            order_count: '00:00',
            setTime: null,
            // 1
            colors: ['#99A9BF', '#F7BA2A', '#f54123'],
            rate_val: 4.9,

        }
    },
    created() {
        let params = this.$route.params;
        if(Object.keys(params).length > 0){
            this.order_sn = params.order_sn
            this.order_status = params.order_status;
        }else{
            this.order_status = 2;
        }
        if (this.order_status == 0) {
            this.onGetOrder();
            this.setIntime();
        }
    },
    mounted() {

    },
    beforeDestroy(){
        clearTimeout(timer)
        clearInterval(this.setTime)
    },
    computed:{
        userData:function(){
            return this.$store.getters.userData || this.$storage.get('userData')
        },
    },
    methods: {
        setIntime() {
            // let min = 0,
            //     num = 0;
            clearInterval(this.setTime)
            this.setTime = setInterval(() => {
                // ++num
                // if (min < 5) {
                //     if (num >= 60) {
                //         min += 1;
                //         num = 0;
                //     }
                //     this.order_count = `${min>=10?min:'0' + min}:${num>=10?num:'0' + num}`
                // } else {
                //     clearInterval(this.setTime);
                // }
                this.onGetOrder();
            }, 5000)
        },
        toOrderList(){
            this.$router.push('/index/orderList')
        },
        onGetOrder() {
            let order_sn = this.order_sn;
            let datakey = {};
            datakey['user_sn'] = this.userData.user_sn
            datakey['order_sn'] = order_sn
            handleGetOrder(datakey).then(datas=>{
                if (datas.status == 0) {
                    let orderData = datas.data
                    this.getCargoNum(orderData)
                    let order_add_time = orderData.order_info.add_time
                    let timestamp = Date.parse(new Date()) / 1000
                    let pass_time = timestamp - order_add_time;
                    this.pass_time = pass_time;
                    this.orderData = orderData;

                    let order_status = orderData.order_info.order_status
                    let ways = []
                    if(order_status==11){
                        this.getPassTime(pass_time)
                    }
                    let type
                    let location = orderData.location
                    if (order_status >= 20) {
                        if (order_status == 20) {
                            this.order_status = 1;
                            let end = orderData.haulwayList[0]
                            ways.unshift(location, end)
                            type = 0
                        } else {
                            ways = orderData.haulwayList
                            type = 1
                        }
                        if(orderData.location){
                            this.latitude = orderData.location.lat;
                            this.longitude = orderData.location.lng;
                            this.markers = [];
                        }else{
                            this.latitude = ways[0].lat;
                            this.longitude = ways[0].lng;
                            this.markers = [];
                        }
                        
                        this.setOrderRoute(ways, type)
                    }
                }
            }).catch(err=>{
                console.log(err)
            })
        },
        getCargoNum(data){
            let haulwayList = data.haulwayList
            let load_num = 0
            let unload_num = 0
            for(let item of haulwayList){
                if(item.action==1){
                    load_num+=item.cargo_num
                }else if(item.action==-1){
                    unload_num+=item.cargo_num
                }
            }
            this.load_num = load_num;
            this.unload_num = unload_num;
        },
        getPassTime(pass_time) {
            let that = this
            let orderData = this.orderData
            clearTimeout(timer)
            timer = setTimeout(function () {
                pass_time++
                if (pass_time >= 180) {
                    if (orderData.order_info.back_car == 1) {
                        that.showTips()
                    }
                }
                that.pass_time = pass_time
                that.getPassTime(pass_time)
            }, 1000)
        },
        showTips() {
            if (isShowTips) {
                return false
            }
            isShowTips = true
            this.$confirm('您当前等候时间过长,是否补上差额一键转为专车?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'info'
            }).then(() => {
                this.canUpgrade = true;
                this.upgradeCar()
            }).catch(() => {
                this.canUpgrade = true;
            });
        },
        //升级成为专车
        upgradeCar() {
            let url = URLconstant.upgradeCar
            let orderData = this.data.orderData
            let datakey = {}
            datakey['user_sn'] = this.userData.user_sn
            datakey['order_sn'] = orderData.order_sn
            datakey['back_car'] = 0;
            handleUpgradeCar(datakey).then(datas=>{
                this.$message.info(datas.msg)
                if (datas.status == 0) {
                    let data = datas.data
                    this.orderData = data;
                    this.$storage.set('orderData',data)
                }
            }).catch(err=>{
                this.$message.error(err.msg)
            })
        },
        // 订单匹配成功，画路线
        setOrderRoute(ways, type) {
            if (!ways || ways.length == 0) {
                return false
            }
            let that = this
            let length = ways.length
            let start = ways.shift()
            let end = ways.pop()
            let waypoints = ''
            for (let item of ways) {
                let way
                way = item.lat + ',' + item.lng
                waypoints += waypoints == '' ? way : ';' + way
            }
            // 这里是订单匹配成功后，地图显示路线
            // qqmapsdk.direction({
            //     mode: 'driving',
            //     from: start.lat + ',' + start.lng,
            //     to: end.lat + ',' + end.lng,
            //     waypoints: waypoints,
            //     sig: 'VkWsVl71jO4kl6w2CdrAleZMocZTWVD',
            //     success: function (res) {
            //         that.setPolyline(res.result.routes[0].polyline, res.result.routes[0].waypoints, type)
            //     },
            //     fail: function (res) {
            //         console.log(JSON.stringify(res))
            //     }
            // })
        },
        //取消订单
        cannelOrder() {
            let orderData = this.orderData
            let status = orderData.order_info.order_status
            if (status >= 30) {
                this.$notify.warning({
                    title:'提示',
                    message:'司机已经装货发车,取消订单需联系客服协商'
                })
                return false
            }
            this.$storage.set('orderData',orderData)
            this.showCancelTips = true;
        },
        selectTips(data){
            let item = data;
            this.msg_id = item.msg_id;
        },
        confirmCancel(){
            let orderData = this.orderData
            let datakey = {}
            let msg_id = this.msg_id
            if (msg_id == null || msg_id == '') {
                this.$message.warning('请选择取消订单原因')
                return false
            }
            datakey['user_sn'] = this.userData.user_sn
            datakey['order_sn'] = this.order_sn
            datakey['msg_id'] = this.msg_id
            handleCannelOrder(datakey).then(datas=>{
                if (datas.status == 0) {
                    // app.globalData.refresh = false
                    this.$confirm('订单取消成功', '提示', {
                        confirmButtonText: '确定',
                        showCancelButton:false,
                        type: 'info'
                    }).then(() => {
                        this.$storage.remove('orderData');
                        this.$router.replace({path:'/index/orderList'})
                    }).catch(() => {
                    });
                } else {
                    this.$message.error(datsa.msg)
                }
                this.showCancelTips = false;
            }).catch(err=>{
                console.log(err)
            })
        },
        
    },
    filters:{
        timeToClock: function(time, type = false) {
            var hour = 0
            var minute = 0
            var second = 0
            if (time > 60) {
                second = parseInt(time % 60)
                minute = parseInt(time / 60)
                if (minute > 60) {
                    hour = parseInt(minute / 60)
                    minute = parseInt(minute % 60)
                }
            } else {
                second = time
            }
            hour = hour < 10 ? '0' + hour : hour
            minute = minute < 10 ? '0' + minute : minute
            second = second < 10 ? '0' + second : second
            if (type == 'hour') {
                return hour
            } else if (type == 'minute') {
                return minute
            } else if (type == 'second') {
                return second
            } else {
                return hour + ':' + minute + ':' + second
            }
        },
        timestampToTime: function(timestamp) {
            if (timestamp != undefined) {
                var date = getDate(timestamp * 1000)
                var Y = date.getFullYear() + '-';
                var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
                var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
                var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
                var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
                return Y + M + D + h + m + s;
            }
        }
    }
}
</script>
<style lang="scss">
    .match_container{
        height: 100%;
        .matching {
            padding-top: 100px;
        }
        
        .set_time {
            width: 180px;
            height: 180px;
            border-radius: 180px;
            border: 1px solid #d8d8d8;
            text-align: center;
            line-height: 180px;
            font-size: 32px;
            color: #02a7f0;
            margin-bottom: 26px;
        }
        
        .matching_status {
            font-size: 15px;
            color: #02a7f0;
            margin-bottom: 6px;
        }
        
        .matching_status_text {
            font-size: 15px;
            color: #02a7f0;
            margin-bottom: 10px;
        }
        
        .matching_tip {
            font-size: 14px;
            color: #999;
            margin-bottom: 20px;
            line-height: 1.2;
        }
        
        .match_info {
            height: 100%;
        }
        
        .match_info img,
        .match_info span {
            margin-bottom: 10px;
        }
        /*#region*/
        
        .match_success {
            margin-top: 120px;
        }
        
        .match_success .order_avatar {
            width: 100px;
            height: 100px;
            border-radius: 100px;
            object-fit: cover;
            margin-bottom: 10px;
        }
        
        .match_success .el-rate {
            margin-bottom: 6px;
        }
        
        .match_success .el-rate__icon {
            margin-right: 0;
            font-size: 22px;
        }
        
        .order_user_name {
            font-size: 22px;
            color: #f54123;
            text-align: center;
            margin-bottom: 6px;
        }
        
        .car_number {
            font-size: 14px;
            color: #333;
            margin-bottom: 10px;
        }
        
        .success_info {
            width: 94%;
            border-radius: 10px;
            border: 1px solid #ddd;
            padding: 0 20px;
        }
        
        .success_content {
            padding: 20px 0;
        }
        
        .success_title {
            height: 50px;
            border-bottom: 1px dashed #ddd;
        }
        
        .suc_lf {
            font-size: 15px;
        }
        
        .order_mileage {
            flex: 1 1;
        }
        
        .mileage_lf {
            margin-bottom: 16px;
        }
        
        .start_mileage {
            margin-right: 10px;
        }
        
        .start_icon,
        .end_icon {
            width: 30px;
            height: 30px;
            border-radius: 30px;
            font-size: 14px;
            text-align: center;
            line-height: 30px;
            font-size: 14px;
            color: #fff;
            margin-right: 20px;
        }
        
        .start_icon {
            background: #299ede;
        }
        
        .end_icon {
            background: #f54123;
        }
        /*#endregion*/
    }
    
</style>