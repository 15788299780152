import { axiosGet, axiosPost } from "@/api/request"
import apis from "@/api/apis"

// 获取订单数据
export function handleGetOrder(data) {
    return axiosGet(apis.orderDetail, data);
}
// 升级为专车
export function handleUpgradeCar(data) {
    return axiosPost(apis.upgradeCar, data)
}
// 获取取消订单原因
export function handleGetCannelTips() {
    return axiosGet(apis.cannelTips)
}
// 取消订单
export function handleCannelOrder(data) {
    return axiosPost(apis.cannelOrder, data)
}