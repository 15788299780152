<template>
    <div class="tips_class" v-if="show">
        <el-dialog
            title="请至少从以下中选择一项提交"
            :visible.sync="show"
            :show-close="false">

            <div class="header_row">
                <span class="font_size26 gray_color margin_bottom">请至少从以下中选择一项提交</span>
                <span class="font_size30 black_color">司机原因:</span>
            </div>
            <div class="tips_view">
                <div class="tips_item" @click.stop="selectTips(item)" v-for="(item,index) in tipsData && tipsData.motormanNoteList" :key="index">
                    <div class="circle_view">
                        <div v-if="msg_id == item.msg_id" class="select_circle"></div>
                    </div>
                    <span class="font_size28 black_color">{{item.msg}}</span>
                </div>
            </div>
            <div class="header_row">
                <span class="font_size30 black_color">个人原因:</span>
            </div>
            <div class="tips_view">
                <div v-for="(item,index) in tipsData && tipsData.userNoteList" :key="index" class="tips_item" @click="selectTips(item)">
                    <div class="circle_view">
                        <div v-if="msg_id==item.msg_id" class="select_circle"></div>
                    </div>
                    <span class="font_size28 black_color">{{item.msg}}</span>
                </div>
            </div>
            <div class="header_row">
                <span class="font_size26 gray_color text_center">个人原因取消的将会记录一次违约,多次违约后平台将会进行限制订单</span>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="onCancel">取 消</el-button>
                <el-button type="primary" @click="confirmCancel">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import {handleGetCannelTips} from '@api/matchOrder'
export default {
    props:{
        show:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
            tipsData:null,      //提示的数据
            msg_id:'',
        }
    },
    created(){
        this.onGetCannelTips();
    },
    methods:{
        selectTips(data){
            let item = data;
            this.msg_id = item.msg_id;
            this.$emit('select',item)
        },
        // 获取取消订单原因列表
        onGetCannelTips() {
            let datakey = {};
            handleGetCannelTips().then(datas=>{
                this.tipsData = datas.data
            }).catch(err=>{
            })
        },
        onCancel(){
            this.$emit('cancel')
        },
        confirmCancel(){
            this.msg_id = ''; 
            this.$emit('confirm')
        },
    },
}
</script>
<style lang="scss">
    .tips_class{
        .header_row{
            padding: 15px;
            display: flex;
            flex-direction: column;
        }
        .font_size26{
            font-size: 13px;
        }
        .font_size30{
            font-size: 15px;
        }
        .font_size28{
            font-size: 14px;
        }
        .gray_color{
            color:#aaa;
        }
        .black_color{
            color:#333;
        }
        .margin_bottom{
            margin-bottom: 10px;
        }
        .tips_view{
            padding: 10px;
            background: #fff;
        }
        .tips_item{
            padding: 10px 0;
            display: flex;
            align-items: center;
            cursor: pointer;
        }
        .circle_view{
            width:18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border:1px solid #e0e0e0;
            margin-right: 10px;
            position: relative;
        }
        .select_circle{
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            width:12px;
            height: 12px;
            border-radius: 50%;
            background: #299EDE;
        }
        .text_center{
            text-align: center;
        }
        .submit_btn{
            width:100%;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            font-size: 16px;
            color:#fff;
            margin: 50px auto;
        }
    }
</style>